import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import Input from '../../components/input/input';
import Button from '../../components/button/button';
import { useForgotPasswordMutation } from '../../services/api/auth';
import { isCustomErrorResponse } from '../../utils/errorUtils';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Logo from '/src/assets/logo.svg';

export default function ForgotPassword() {
	const [email, setEmail] = useState('');
	const [countdown, setCountdown] = useState<number>(60);
	const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

	const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

	const handleForgotPassword = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsButtonDisabled(true);
		setCountdown(60);
		try {
			const response = await forgotPassword({ email }).unwrap();

			if (response) {
				toast.success(response.message);
				setEmail('');
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again.');
			}
		}
	};

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (isButtonDisabled && countdown > 0) {
			timer = setTimeout(() => setCountdown((prev) => prev - 1), 1000);
		} else if (countdown === 0) {
			setIsButtonDisabled(false);
		}
		return () => clearTimeout(timer);
	}, [isButtonDisabled, countdown]);

	return (
		<div className="relative flex flex-col h-svh  overflow-y-auto items-center justify-center mx-2">
			<Helmet>
				<title>Forgot Password</title>
				<meta name="description" content="Forgot password page." />
			</Helmet>
			<div className="absolute top-0 left-0 w-full flex justify-start p-2 sm:p-4 items-center gap-2">
				<img src={Logo} alt="logo" className="w-10 h-10" />
				<Link to="/" className="text-lt-beige dark:text-white font-extrabold text-2xl">
					Life on Track
				</Link>
			</div>
			<div className="absolute top-[72px] flex flex-col items-center py-6 sm:py-10 gap-2 sm:gap-4 w-full bg-white dark:bg-lt-dark-popupBackground max-w-[600px]  border border-lt-beige dark:border-lt-dark-primary-border rounded-md sm:rounded-lg sm:shadow-xl">
				<h2 className="font-bold text-4xl sm:text-5xl mb-4">Forgot Password</h2>
				<form
					className="flex flex-col justify-center items-center gap-4 w-full max-w-xs"
					onSubmit={handleForgotPassword}
				>
					<Input
						type="email"
						name="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						placeholder="Enter your email"
						required
					/>
					<Button
						disabled={!email || isLoading || isButtonDisabled}
						variant="btn-primary-light"
						type="submit"
						className="w-full"
					>
						{isLoading ? (
							<span className="loading loading-spinner loading-md"></span>
						) : isButtonDisabled ? (
							`Resend in ${countdown}s`
						) : (
							'Send Reset Email'
						)}
					</Button>
				</form>

				<Link className="underline text-lt-beige" to={'/auth/login'}>
					Back to login
				</Link>
			</div>
		</div>
	);
}
