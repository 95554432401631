import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Logo from '/src/assets/logo.svg';
import KVKK from '/src/assets/KVKK.pdf';

export default function PrivacyPolicyEn() {
	const pathname = window.location.pathname;
	const parts = pathname.split('/');
	const language = parts[1];

	return (
		<div>
			<Helmet>
				<title>Life on Track - Privacy Policy</title>
				<meta name="description" content="Privacy policy of Life on Track." />
			</Helmet>
			<header className="sticky top-0 z-50 w-full h-[70px] px-4 md:px-12 flex items-center justify-between border-b  bg-lt-lightCream dark:bg-lt-dark-secondary-bg">
				<div className="flex justify-start items-center md:gap-2 w-4/12 md:w-1/4">
					<img src={Logo} alt="logo" className="w-8 h-8 md:w-10 md:h-10" />
					<Link to="/" className="text-lt-beige dark:text-white font-extrabold text-sm sm:text-2xl">
						Life on Track
					</Link>
				</div>
				<div className="hidden md:flex justify-between base:justify-center base:gap-10 md:w-1/2">
					<Link
						to="/en/terms-of-service"
						className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
					>
						Terms of Service
					</Link>
					<Link
						to="/en/refund-policy"
						className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
					>
						Refund Policy
					</Link>
					<Link
						to="/"
						className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
					>
						Home
					</Link>
				</div>
				<div className="navbar w-1/3 flex justify-center md:hidden">
					<div className="navbar-center md:hidden">
						<ul className="menu menu-md menu-horizontal px-1">
							<li>
								<details>
									<summary className="text-lt-beige dark:text-[#5D4037] active:bg-lt-lightCream active:text-lt-beige dark:active:bg-transparent">
										Menu
									</summary>
									<ul className="flex flex-col text-xs sm:text-base font-semibold justify-between text-black dark:text-white dark:bg-lt-dark-popupBackground  divide-y divide-lt-beige dark:divide-[#6E6E40] bg-lt-lightCream px-0 ">
										<li className="px-4">
											<Link
												to="/en/terms-of-service"
												className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
											>
												Terms of Service
											</Link>
										</li>
										<li className="px-4">
											<Link
												to="/en/refund-policy"
												className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
											>
												Refund Policy
											</Link>
										</li>
										<li className="px-4">
											<Link
												to="/"
												className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
											>
												Home
											</Link>
										</li>
									</ul>
								</details>
							</li>
						</ul>
					</div>
				</div>
				<div className="flex justify-end gap-1 sm:gap-4 text-xs sm:text-base md:text-xl w-2/12 md:w-1/4">
					<Link
						className={`${language === 'en' ? 'text-lt-beige dark:text-[#5D4037]' : 'text-black dark:text-white'} hover:text-lt-beige
					}`}
						to="/en/privacy-policy"
					>
						EN
					</Link>
					/
					<Link
						className={`${language === 'tr' ? 'text-lt-beige dark:text-[#5D4037]' : 'text-black dark:text-white'} hover:text-lt-beige
					}`}
						to="/tr/privacy-policy"
					>
						TR
					</Link>
				</div>
			</header>
			<div className="p-6 max-w-4xl mx-auto mt-5 md:mt-10 h-screen overflow-y-auto pb-40">
				<h1 className="text-2xl font-bold mb-4 text-center">PERSONAL DATA PROCESSING CLARIFICATION TEXT</h1>

				<section className="mb-6">
					<h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
					<p className="mb-2">
						As <strong>Kaan Mert ("Kaan Software Studio")</strong>, the privacy, security, and protection of your
						personal data are among our top priorities. With this awareness, we implement necessary procedures to ensure
						that all personal data communicated to us during the use of the Life on Track application is processed and
						stored in accordance with the Turkish Personal Data Protection Law No. 6698 (<strong>“KVKK”</strong> or{' '}
						<strong>"Law"</strong>) and the related legislation.
					</p>
					<p>
						As the Processor, we aim to inform you with this Clarification Text, in accordance with KVKK, on how your
						personal data collected within the scope of the services we offer through Life on Track is processed.
					</p>
				</section>

				<section className="mb-6">
					<h2 className="text-xl font-semibold mb-2">2. Which Personal Data Do We Process?</h2>
					<p className="mb-2">When you use Life on Track, the following personal data is processed:</p>
					<table className="min-w-full border border-gray-300 mb-4">
						<tbody>
							<tr>
								<td className="border px-4 py-2 font-bold">ACCOUNT INFORMATION</td>
								<td className="border px-4 py-2">User ID (*), Username (*)</td>
							</tr>
							<tr>
								<td className="border px-4 py-2 font-bold">CONTACT INFORMATION</td>
								<td className="border px-4 py-2">Email address registered to your Life on Track account (*)</td>
							</tr>
							<tr>
								<td className="border px-4 py-2 font-bold ">PROFILE PHOTO</td>
								<td className="border px-4 py-2">Profile photo uploaded to your Life on Track account</td>
							</tr>
							<tr>
								<td className="border px-4 py-2 font-bold ">ACCOUNT BACKGROUND PHOTO</td>
								<td className="border px-4 py-2">Background photo uploaded to your Life on Track account</td>
							</tr>
							<tr>
								<td className="border px-4 py-2 font-bold ">CUSTOMER INFORMATION</td>
								<td className="border px-4 py-2">
									Data history entered in the tables within Life on Track (*), Table information (*), Information
									provided in the feedback form if filled out
								</td>
							</tr>
							<tr>
								<td className="border px-4 py-2 font-bold ">OPERATION SECURITY INFORMATION</td>
								<td className="border px-4 py-2">User transaction records (*), Login method (via email/Google) (*)</td>
							</tr>
						</tbody>
					</table>
					<p className="text-xs text-gray-500">
						Data that must absolutely be processed is marked with *. Processing of other data depends on the user's
						decision to share it with Kaan Software Studio through Life on Track.
					</p>
				</section>

				<section className="mb-6">
					<h2 className="text-xl font-semibold mb-2">
						3. Methods, Purposes, and Legal Reasons for Processing Your Personal Data
					</h2>
					<p className="mb-2">
						Your personal data is collected electronically, in whole or in part, through the website of the service you
						use by Kaan Software Studio, and is processed based on the legal grounds set out in Article 5 of KVKK.
					</p>
					<p className="mb-2">Below are the purposes and legal reasons for processing your personal data:</p>
					<h3 className="text-lg font-semibold mb-1">3.1 Membership and Account Creation Process:</h3>
					<p className="mb-2 font-medium">
						<strong>Personal data processed for this purpose:</strong> Account information, contact information,
						customer information, operation security information, profile photo, account background photo.
					</p>
					<h3 className="font-semibold">Purposes:</h3>
					<ul className="list-disc ml-6 mb-4">
						<li>Execution of processes related to the establishment and performance of the membership agreement,</li>
						<li>Management of member profile/account creation and closure processes,</li>
						<li>Verification of contact information,</li>
						<li>Carrying out activities related to the realization of service purchase operations,</li>
						<li>Conducting and supervising our business activities,</li>
						<li>Ensuring the security of our operations,</li>
						<li>Fulfilling obligations arising from applicable regulations,</li>
						<li>Conducting application activities in compliance with the law,</li>
						<li>Fulfilling data sharing and retention obligations arising from applicable regulations.</li>
					</ul>
					<p className="mb-4">
						<span className="font-semibold">Legal Reasons:</span> Explicitly stipulated in the laws (KVKK Article
						5/2-a), necessary for the establishment and performance of the user agreement (KVKK Article 5/2-c),
						mandatory for the fulfillment of our legal obligations (KVKK Article 5/2-ç).
					</p>
				</section>
				<h3 className="text-lg font-semibold mb-1">3.2 Information Security, Risk, Audit, and Control Processes:</h3>
				<p className="mb-2 font-medium">
					<strong>Personal data processed in this category:</strong> Account information, contact information, customer
					information, operation security information.
				</p>
				<h3 className="font-semibold">Purposes:</h3>
				<ul className="list-disc ml-6 mb-4">
					<li>Conducting information security processes and investigating suspicious operations,</li>
					<li>Ensuring the security of operations,</li>
					<li>Controlling, improving, and enhancing the quality of services provided by Life on Track,</li>
					<li>Handling complaints and feedbacks.</li>
				</ul>
				<p className="mb-4">
					<span className="font-semibold">Legal Reasons:</span> Necessary for the performance of the user agreement
					(KVKK Article 5/2-c), mandatory for the processing of data due to our legitimate interests (KVKK Article
					5/2-f).
				</p>
				<h3 className="text-lg font-semibold mb-1">3.3 Legal Process Execution:</h3>
				<p className="mb-2 font-medium">
					<strong>Personal data processed in this category:</strong> Account information, contact information, customer
					information, operation security information.
				</p>
				<h3 className="font-semibold">Purposes:</h3>
				<ul className="list-disc ml-6 mb-4">
					<li>
						Conducting legal and litigation processes, carrying out legal consultancy processes, responding to your
						legal requests, examining and responding to your applications as the relevant party,
					</li>
					<li>Ensuring that our activities are carried out in accordance with legislation,</li>
					<li>Fulfilling obligations arising from the legislation we are subject to,</li>
					<li>Sharing information with authorized persons, institutions, and organizations upon request,</li>
					<li>Exercising and protecting a right in the event of a legal dispute.</li>
				</ul>
				<p className="mb-4">
					<span className="font-semibold">Legal Grounds:</span> Mandatory for the fulfillment of our legal obligations
					(KVKK Article 5/2-ç), mandatory for the establishment, exercise, or protection of a right (KVKK Article
					5/2-e).
				</p>

				<h2 className="text-xl font-semibold mb-2">
					4. To Whom and For What Reasons Are Your Personal Data Transferred?
				</h2>
				<p className="mb-2">
					In accordance with Articles 8 and 9 of the KVKK, your personal data may be transferred to Kaan Software Studio
					employees, officers, legally authorized public institutions and organizations, and domestic and international
					business partners, database, and service providers from whom we receive services or with whom we collaborate
					for the execution of services and/or activities, in a manner that is connected, limited, and proportional to
					the purpose in the following cases:
				</p>

				<h3 className="text-lg font-semibold mb-1">4.1 Personal Data Transferred:</h3>
				<p className="mb-2 font-medium">
					Account information, contact information, customer information, operation security information.
				</p>
				<p className="mb-4">
					<span className="font-semibold">Purposes:</span> Receiving support from our suppliers, including those
					providing infrastructure and IT services related to the services offered, and conducting financial and
					accounting operations.
				</p>
				<p className="mb-4">
					<span className="font-semibold">Legal Grounds:</span> Mandatory for the fulfillment of our legal obligations
					(KVKK Article 5/2-ç), your explicit consent for the transfer of your personal data abroad (KVKK Article 9).
				</p>

				<h3 className="text-lg font-semibold mb-1">4.2 Personal Data Transferred:</h3>
				<p className="mb-2 font-medium">
					Account information, contact information, customer information, operation security information.
				</p>
				<p className="mb-4">
					<span className="font-semibold">Purposes:</span> Fulfilling our legal obligations, providing information to
					authorized persons, institutions, or organizations within this scope, conducting legal processes, and ensuring
					that our activities are carried out in compliance with regulations.
				</p>
				<p className="mb-4">
					<span className="font-semibold">Legal Grounds:</span> Mandatory for the fulfillment of our legal obligations
					(KVKK Article 5/2-ç).
				</p>
				<section className="py-6 max-w-4xl mx-auto">
					<h2 className="text-xl font-semibold mb-4">
						5. What Rights Do You Have Regarding the Protection of Your Personal Data?
					</h2>
					<p className="mb-2">
						Pursuant to Article 11 of the KVKK, you have the following rights regarding the protection of your personal
						data:
					</p>
					<ul className="list-inside ml-6 mb-4" style={{ listStyleType: 'lower-alpha' }}>
						<li>To learn whether your personal data is being processed,</li>
						<li>To request information if your personal data has been processed,</li>
						<li>
							To learn the purpose of processing your personal data and whether they are used in accordance with the
							intended purpose,
						</li>
						<li>To know the third parties to whom your personal data is transferred, either domestically or abroad,</li>
						<li>
							To request the correction of your personal data if it has been incompletely or incorrectly processed,
						</li>
						<li>
							To request the deletion or destruction of your personal data within the framework of the conditions set
							forth in Article 7 of the Law,
						</li>
						<li>
							To request that your requests for correction, deletion, or destruction of personal data be communicated to
							third parties to whom the personal data has been transferred,
						</li>
						<li>
							To object to any outcome against you resulting from the analysis of your processed data exclusively
							through automated systems,
						</li>
						<li>To demand compensation if you suffer damages due to the unlawful processing of your personal data.</li>
					</ul>
				</section>
				<section className="py-6 max-w-4xl mx-auto">
					<h2 className="text-xl font-semibold mb-4">
						6. Contact for Requests Regarding the Protection of Your Personal Data
					</h2>
					<p className="mb-2">
						You can submit your requests regarding your rights as regulated in Article 11 of the KVKK by completing and
						signing the Application Form({' '}
						<a className="font-semibold" href={KVKK} download="Life-on-Track-KVKK-Başvuru-Formu.pdf">
							Form
						</a>
						) and sending it electronically from your registered email address to our email address:{' '}
						<a className="hover:underline font-semibold" href="mailto:legal@lifeontrack.co">
							legal@lifeontrack.co
						</a>
						.
					</p>
					<p className="mb-4">
						Your applications will be finalized as soon as possible and within no later than 30 (thirty) days, free of
						charge, pursuant to Article 13 of the KVKK, and you will be informed accordingly.
					</p>
					<h3 className="text-lg font-semibold mb-2">7. Data Controller's Contact Information</h3>
					<table className="min-w-full border border-gray-300">
						<tbody>
							<tr>
								<td className="border px-4 py-2 font-semibold">Name-Surname</td>
								<td className="border px-4 py-2">Kaan MERT</td>
							</tr>
							<tr>
								<td className="border px-4 py-2 font-semibold">Title</td>
								<td className="border px-4 py-2">Kaan MERT</td>
							</tr>
							<tr>
								<td className="border px-4 py-2 font-semibold">E-mail</td>
								<td className="border px-4 py-2">kaan@thripl.co</td>
							</tr>
							<tr>
								<td className="border px-4 py-2 font-semibold">Address</td>
								<td className="border px-4 py-2">Yağcılar Mahallesi Saadet Sokağı No: 18/- Adapazarı/ Sakarya</td>
							</tr>
						</tbody>
					</table>
				</section>
			</div>
		</div>
	);
}
