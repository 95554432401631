import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { isCustomErrorResponse } from '../../utils/errorUtils';
import { useVerifyEmailMutation } from '../../services/api/auth';
import { resetEmail } from '../../states/auth/emailSlice';
import { RootState } from '../../states/store';
import { Helmet } from 'react-helmet';
import Logo from '/src/assets/logo.svg';

export const VerifyEmail = () => {
	const dispatch = useDispatch();
	const [verifyEmailMutation, { isLoading }] = useVerifyEmailMutation();
	const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
	const navigate = useNavigate();
	const location = useLocation();

	const clearEmail = () => {
		dispatch(resetEmail());
	};

	useEffect(() => {
		if (!isAuthenticated) {
			const verifyEmail = async () => {
				const params = new URLSearchParams(location.search);
				const token = params.get('token');

				if (token) {
					try {
						const response = await verifyEmailMutation(token).unwrap();

						if (response) {
							toast.success(response.message);
						}
					} catch (error) {
						navigate('/auth/register');

						if (isCustomErrorResponse(error)) {
							toast.error(error.data.message);
						} else if (error instanceof Error) {
							toast.error(error.message);
						} else {
							toast.error('An unknown error occurred. Please try again later.');
						}
					}
				} else {
					navigate('/auth/register');
					toast.error('Error verifying email. Please try again.');
				}
			};

			verifyEmail();
		}
	}, [location.search, navigate, isAuthenticated, verifyEmailMutation]);

	if (isLoading) {
		return (
			<div className="flex flex-col justify-center items-center min-h-screen">
				<span className="loading loading-spinner loading-lg"></span>
			</div>
		);
	}

	return (
		<div className="relative flex flex-col h-svh  overflow-y-auto items-center justify-center mx-2">
			<Helmet>
				<title>Life on Track - Email Verification</title>
				<meta name="description" content="Email verification successful!" />
			</Helmet>
			<div className="absolute top-0 left-0 w-full flex justify-start p-2 sm:p-4 items-center gap-2">
				<img src={Logo} alt="logo" className="w-10 h-10" />
				<Link to="/" className="text-lt-beige dark:text-white font-extrabold text-2xl">
					Life on Track
				</Link>
			</div>
			<div className="absolute top-[72px] flex flex-col items-center py-6 sm:py-10 gap-2 sm:gap-4 w-full bg-white dark:bg-lt-dark-popupBackground max-w-[600px]  border border-lt-beige dark:border-lt-dark-primary-border rounded-md sm:rounded-lg sm:shadow-xl">
				<h2 className="text-2xl font-bold">Email Verification Successful!</h2>
				<p className="mt-4">Your email has been successfully verified.</p>
				<Link onClick={clearEmail} to="/auth/login" className="mt-4 underline text-lt-beige dark:text-white">
					Go to Login
				</Link>
			</div>
		</div>
	);
};
