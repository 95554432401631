import React, { useCallback, useEffect, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	IconCamera,
	IconCoinFilled,
	IconEye,
	IconEyeOff,
	IconLock,
	IconPhoto,
	IconUserMinus,
	IconUserPlus
} from '@tabler/icons-react';
import {
	useLazyViewProfileQuery,
	useUploadProfilePhotoMutation,
	useUploadBackgroundPhotoMutation,
	IProfile,
	useDeleteProfileMutation,
	useUnfollowMutation,
	useSendFollowRequestMutation,
	useChangePasswordMutation,
	SubscriptionType,
	useLazyClaimStreakRewardRequestQuery,
	useApplyPromoCodeMutation
} from '../../../services/api/profile';
import { isCustomErrorResponse } from '../../../utils/errorUtils';
import noProfilePicture from '/src/assets/no-profile-picture.svg';
import noBackgroundPicture from '/src/assets/bg-image.svg';
import Achievements from '../../../components/profile/achievements/Achievements';
import Streaks from '../../../components/profile/streaks/Streaks';
import Stickers from '../../../components/profile/stickers/Stickers';
import { useLazyGetMeQuery } from '../../../services/api/auth';
import { persistor, resetAction, RootState } from '../../../states/store';
import Button from '../../../components/button/button';
import {
	applyPromoCodeSuccess,
	cancelSubscriptionSuccess,
	claimStreakRewardSuccess,
	getMyProfileSuccess,
	resumeSubscriptionSuccess,
	unfollowSuccess,
	uploadProfileBackgroundSuccess,
	uploadProfileImageSuccess
} from '../../../states/profile/profileSlice';
import { FlipBook } from '../MyJournal/MyJournal';
import CustomModule from '../../../components/customModule/customModule';
import getCroppedImg from './CropImage';
import './Profile.css';
import Modal from 'react-modal';
import { useLogout } from '../../../hooks/useLogout';
import Input from '../../../components/input/input';
import Followings from '../../../components/profile/followings/Followings';
import Followers from '../../../components/profile/followers/Followers';
import FollowRequests from '../../../components/profile/followRequests/FollowRequests';
import PasswordPopup from '../../../components/password-popup/PasswordPopup';
import { Helmet } from 'react-helmet';
import Subscription from '../Subscription/Subscription';
import { useCancelSubscriptionMutation, useResumeSubscriptionMutation } from '../../../services/api/payment';
import { logoutSuccess } from '../../../states/auth/authSlice';

interface CropArea {
	x: number;
	y: number;
	width: number;
	height: number;
}

export default function Profile() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
	const { userId } = useParams<string>();
	const isMe = !userId;
	const [bgImageUrl, setBgImageUrl] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isBgLoading, setIsBgLoading] = useState<boolean>(false);
	const [myData, setMyData] = useState<IProfile | null>(null);
	const [isMyDataLoading, setIsMyDataLoading] = useState<boolean>(true);
	const { profile } = useSelector((state: RootState) => state.profile);
	const [getMe] = useLazyGetMeQuery();
	const [getUserProfile, { isLoading: userProfileIsLoading }] = useLazyViewProfileQuery();
	const [claimStreakRewardRequest, { isLoading: claimStreakRewardLoading }] = useLazyClaimStreakRewardRequestQuery();
	const [isFollowing, setIsFollowing] = useState<boolean>(false);
	const [userData, setUserData] = useState<IProfile | null>(null);
	const [isUserLoading, setIsUserLoading] = useState<boolean>(false);
	const [uploadProfileImg] = useUploadProfilePhotoMutation();
	const [uploadBgImg] = useUploadBackgroundPhotoMutation();
	const [unfollowRequest, { isLoading: unfollowRequestIsLoading }] = useUnfollowMutation();
	const [sendFollowRequest, { isLoading: sendFollowReqIsLoading }] = useSendFollowRequestMutation();
	const [isValidAccount, setIsValidAccount] = useState<boolean>(true);
	const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState<CropArea | null>(null);
	const [modalIsOpen, setIsOpen] = useState(false);
	const logout = useLogout();
	const [deleteProfileReq] = useDeleteProfileMutation();
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const [followRequestSendFlag, setFollowRequestSendFlag] = useState(false);
	const [changePasswordRequest] = useChangePasswordMutation();
	const [isChangePasswordLoading, setIsChangePasswordLoading] = useState(false);
	const [isPasswordPopupVisible, setIsPasswordPopupVisible] = useState(false);
	const [popupStyle, setPopupStyle] = useState({ top: 0, left: 0 });
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [newPassword, setNewPassword] = useState('');
	const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = useState(false);
	const [currentPassword, setCurrentPassword] = useState('');
	const subscriptionStartDate = new Date(profile.subscription.subscriptionStartDate);
	const subscriptionEndDate = new Date(profile.subscription.subscriptionEndDate);
	const formattedStartDate = subscriptionStartDate.toLocaleDateString();
	const formattedEndDate = subscriptionEndDate.toLocaleDateString();
	const timeDiff = subscriptionEndDate.getTime() - new Date().getTime();
	const [showFlipBook, setShowFlipBook] = useState(false);
	const [applyPromoCodeReq, { isLoading: isApplyPromoCodeLoading }] = useApplyPromoCodeMutation();
	const [promoCode, setPromoCode] = useState('');
	const createdAt = new Date(profile.createdAt).getTime();
	const today = new Date().getTime();
	const trialTimeDiff = 7 - Math.floor((today - createdAt) / (1000 * 60 * 60 * 24));
	const [isChangeSubscription, setIsChangeSubscription] = useState(false);
	const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
	const [hasRedirectedToCheckout, setHasRedirectedToCheckout] = useState(false);
	const [cancelSubscriptionReq, { isLoading: isCancelSubscriptionLoading }] = useCancelSubscriptionMutation();
	const [resumeSubscriptionReq, { isLoading: isResumeSubscriptionLoading }] = useResumeSubscriptionMutation();

	const dailyRewards = [
		{
			id: 1,
			title: 'Day 1',
			reward: '10 coins'
		},
		{
			id: 2,
			title: 'Day 2',
			reward: '20 coins'
		},
		{
			id: 3,
			title: 'Day 3',
			reward: '30 coins'
		},
		{
			id: 4,
			title: 'Day 4',
			reward: '40 coins'
		},
		{
			id: 5,
			title: 'Day 5',
			reward: '50 coins'
		},
		{
			id: 6,
			title: 'Day 6',
			reward: '60 coins'
		},
		{
			id: 7,
			title: 'Day 7+',
			reward: '70 coins'
		}
	];

	const newPasswordInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (!isFollowing || isMe) return;
		const timeout: NodeJS.Timeout = setTimeout(() => setShowFlipBook(true), 1000);
		return () => clearTimeout(timeout);
	}, [isFollowing, isMe]);

	useEffect(() => {
		const fetchProfile = async () => {
			if (userId) {
				try {
					setIsUserLoading(true);
					const response = await getUserProfile(userId).unwrap();
					if (response) {
						setUserData(response);
						setIsValidAccount(true);
					} else {
						setIsValidAccount(false);
					}
				} catch (error) {
					if (userId === profile._id) {
						navigate('/dashboard/profile');
						return;
					}
					if (isCustomErrorResponse(error)) {
						toast.error(error.data.message);
					} else if (error instanceof Error) {
						toast.error(error.message);
					} else {
						toast.error('An unknown error occurred. Please refresh the page.');
					}
					setIsValidAccount(false);
				} finally {
					setIsUserLoading(false);
				}

				if (profile.followings.includes(userId)) setIsFollowing(true);
				else if (!profile.followings.includes(userId)) setIsFollowing(false);
			} else {
				try {
					const response = await getMe().unwrap();

					if (response) {
						dispatch(getMyProfileSuccess(response));
						setMyData(response);
						setIsValidAccount(true);
						setIsMyDataLoading(false);
					}
				} catch (error) {
					if (isCustomErrorResponse(error)) {
						toast.error(error.data.message);
					} else if (error instanceof Error) {
						toast.error(error.message);
					} else {
						toast.error('An unknown error occurred');
					}
					dispatch(logoutSuccess());
					dispatch(resetAction());
					await persistor.purge();
					navigate('/auth/login');
				}
			}
		};
		if (!isAuthenticated) return;
		fetchProfile();
	}, [userId, isAuthenticated]);

	useEffect(() => {
		const handleResize = () => {
			if (newPasswordInputRef.current) {
				const rect = newPasswordInputRef.current.getBoundingClientRect();
				setPopupStyle({
					top: rect.top + window.scrollY,
					left: rect.right + window.scrollX
				});
			}
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (!isValidAccount) {
			navigate('/404-not-found');
		}
	}, [isValidAccount]);

	useEffect(() => {
		if (!userId) {
			setUserData(null);
			setIsFollowing(false);
		}
	}, [userId]);

	useEffect(() => {
		if (userId) {
			setMyData(null);
		}
	}, [userId]);

	const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);

	const showCroppedImage = useCallback(async () => {
		if (bgImageUrl && croppedAreaPixels) {
			try {
				const croppedImg = await getCroppedImg(bgImageUrl, croppedAreaPixels);
				setIsOpen(false);
				await handleBgUpload(croppedImg);
			} catch (e) {
				console.error(e);
			}
		}
	}, [bgImageUrl, croppedAreaPixels]);

	const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];

			await handleUpload(file);
		}
	};

	const handleUpload = async (file: File) => {
		setIsLoading(true);

		const formData = new FormData();
		formData.append('image', file);

		const maxSize = 1024 * 1024 * 5;
		if (file.size > maxSize) {
			toast.error('Image size must be less than 5MB');
			setIsLoading(false);
			return;
		}

		if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg') {
			toast.error('Invalid file type. Only JPEG, PNG, and JPG files are allowed.');
			setIsLoading(false);
			return;
		}

		try {
			const response = await uploadProfileImg(formData).unwrap();

			if (response) {
				dispatch(uploadProfileImageSuccess(response.url));
				setMyData((prev) => (prev ? { ...prev, profileImgUrl: response.url } : null));
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred');
			}
		} finally {
			setIsLoading(false);
		}
	};

	const handleBgFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];
			const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];

			if (!allowedTypes.includes(file.type)) {
				toast.error('Invalid file type. Please upload an image file');
				return;
			}

			const maxSize = 1024 * 1024 * 5;
			if (file.size > maxSize) {
				toast.error('Image size must be less than 5MB');
				return;
			}

			setBgImageUrl(URL.createObjectURL(file));
			setIsOpen(true);
		}
	};

	const handleBgUpload = async (croppedImg: string) => {
		setIsBgLoading(true);
		try {
			const blob = await fetch(croppedImg).then((r) => r.blob());
			const file = new File([blob], 'cropped-image.jpg', { type: 'image/jpeg' });
			const formData = new FormData();
			formData.append('image', file);

			const response = await uploadBgImg(formData).unwrap();

			if (response) {
				dispatch(uploadProfileBackgroundSuccess(response.url));
				setMyData((prev) => (prev ? { ...prev, profileBackgroundUrl: response.url } : null));
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred');
			}
		} finally {
			setIsBgLoading(false);
		}
	};

	const handleSendRequest = async () => {
		try {
			if (!userId) return;
			const response = await sendFollowRequest(userId).unwrap();
			if (response) {
				toast.success(response.message);
				setFollowRequestSendFlag(true);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		}
	};

	const handleChangePassword = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsChangePasswordLoading(true);
		try {
			const response = await changePasswordRequest({
				currentPassword,
				newPassword
			}).unwrap();

			if (response) {
				toast.success(response.message);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				if (error.data.validationErrors) {
					error.data.validationErrors.forEach((validationError) => {
						const constraints = validationError.constraints;
						const messages = Object.values(constraints).join('. ');
						toast.error(messages);
					});
				} else {
					toast.error(error.data.message);
				}
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		} finally {
			setCurrentPassword('');
			setNewPassword('');
			setIsPasswordVisible(false);
			setIsCurrentPasswordVisible(false);
			(document.getElementById('change_password_modal') as HTMLDialogElement)?.close();
			setIsChangePasswordLoading(false);
		}
	};

	const unfollow = async () => {
		try {
			if (!userId) return;
			const response = await unfollowRequest(userId).unwrap();
			if (response) {
				dispatch(unfollowSuccess(response.followingId));
				toast.success(response.message);
				setIsFollowing(false);
				(document.getElementById('friendDetails-ays-modal') as HTMLDialogElement).close();
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred');
			}
		}
	};

	const openAYSModule = () => {
		(document.getElementById('friendDetails-ays-modal') as HTMLDialogElement).showModal();
	};

	const openDeleteModal = () => {
		(document.getElementById('delete-profile-ays-modal') as HTMLDialogElement).showModal();
	};

	const openCancelSubscriptionModal = () => {
		(document.getElementById('cancel-subscription-ays-modal') as HTMLDialogElement).showModal();
	};

	const openResumeSubscriptionModal = () => {
		(document.getElementById('resume-subscription-ays-modal') as HTMLDialogElement).showModal();
	};

	const handleDelete = async () => {
		try {
			setIsDeleteLoading(true);
			const response = await deleteProfileReq().unwrap();
			if (response) {
				navigate('/auth/login');
				logout();
				toast.success(response.message);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred');
			}
		} finally {
			setIsDeleteLoading(false);
		}
	};

	const handleFocus = () => {
		setIsPasswordPopupVisible(true);
		if (newPasswordInputRef.current) {
			const rect = newPasswordInputRef.current.getBoundingClientRect();
			setPopupStyle({
				top: rect.top + window.scrollY,
				left: rect.right + window.scrollX
			});
		}
	};

	const handleBlur = () => {
		setIsPasswordPopupVisible(false);
	};

	const closeChangePasswordModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		(document.getElementById('change_password_modal') as HTMLDialogElement)?.close();
		setCurrentPassword('');
		setNewPassword('');
		setIsPasswordVisible(false);
		setIsCurrentPasswordVisible(false);
	};

	const claimStreakReward = async () => {
		try {
			const response = await claimStreakRewardRequest().unwrap();
			if (response) {
				dispatch(
					claimStreakRewardSuccess({
						lastClaimDate: response.lastClaimDate,
						appMoney: response.appMoney
					})
				);
				toast.success(response.message);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred');
			}
		}
	};

	const isSameDay = (date1: Date, date2: Date) => {
		return (
			date1.getFullYear() === date2.getFullYear() &&
			date1.getMonth() === date2.getMonth() &&
			date1.getDate() === date2.getDate()
		);
	};

	const applyPromoCode = async () => {
		try {
			const body = {
				promoCode
			};
			const response = await applyPromoCodeReq(body).unwrap();
			if (response) {
				dispatch(applyPromoCodeSuccess(response));
				toast.success(response.message);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred');
			}
		} finally {
			setPromoCode('');
		}
	};

	const cancelSubscription = async () => {
		try {
			const response = await cancelSubscriptionReq().unwrap();
			if (response) {
				dispatch(cancelSubscriptionSuccess(response.status));
				toast.success(response.message);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred');
			}
		} finally {
			(document.getElementById('cancel-subscription-ays-modal') as HTMLDialogElement).close();
		}
	};

	const resumeSubscription = async () => {
		try {
			const response = await resumeSubscriptionReq().unwrap();
			if (response) {
				dispatch(resumeSubscriptionSuccess(response.status));
				toast.success(response.message);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred');
			}
		} finally {
			(document.getElementById('resume-subscription-ays-modal') as HTMLDialogElement).close();
		}
	};

	const changeSubscription = () => {
		setIsChangeSubscription(true);
		setIsSubscriptionModalOpen(true);
	};

	return (
		<div className="w-full flex flex-1 flex-col items-center">
			<Helmet>
				<title>Life on Track - Profile</title>
				<meta
					name="description"
					content="Profile page. View and edit your profile. See your achievements, streaks, and stickers.
					Follow other users and see their profiles.
					Change your password or delete your profile."
				/>
			</Helmet>
			{isSubscriptionModalOpen && (
				<Subscription
					id="subscription-modal"
					isChangeSubscription={isChangeSubscription}
					isSubscriptionModalOpen={isSubscriptionModalOpen}
					setIsSubscriptionModalOpen={setIsSubscriptionModalOpen}
					setIsChangeSubscription={setIsChangeSubscription}
					setHasRedirectedToCheckout={setHasRedirectedToCheckout}
				/>
			)}
			<CustomModule
				id="friendDetails-ays-modal"
				title="Are you sure you want to unfollow?"
				description="You will no longer be able to see their actions."
				buttonText="Unfollow"
				onButtonClick={unfollow}
				isLoading={unfollowRequestIsLoading}
			/>
			<CustomModule
				id="delete-profile-ays-modal"
				title="Delete Profile"
				description="Are you sure you want to delete your profile? This action cannot be undone. If you have an active subscription, it will be cancelled and you will permanently lose all your data including your table entries and subscription benefits."
				buttonText="Delete Profile"
				onButtonClick={handleDelete}
				isLoading={isDeleteLoading}
			/>
			<CustomModule
				id="cancel-subscription-ays-modal"
				title="Cancel Subscription"
				description="Are you sure you want to cancel your subscription? After the end date, you will lose access to subscription benefits."
				buttonText="Cancel Subscription"
				onButtonClick={cancelSubscription}
				isLoading={isCancelSubscriptionLoading}
			/>
			<CustomModule
				id="resume-subscription-ays-modal"
				title="Resume Subscription"
				description="We're glad to see you back! Your subscription will be resumed once you confirm. Click below to enjoy all the benefits again."
				buttonText="Resume Subscription"
				onButtonClick={resumeSubscription}
				isLoading={isResumeSubscriptionLoading}
			/>
			{hasRedirectedToCheckout && (
				<div className="fixed inset-0 z-50 bg-black bg-opacity-50">
					<div className="bg-lt-lightCream dark:bg-lt-dark-secondary-bg p-4 flex flex-col items-center gap-2 absolute top-0 left-0 right-0">
						<p className="text-lt-dark-primary-text dark:text-lt-dark-primary-text text-center">
							Please refresh the page to ensure the latest updates are displayed.
						</p>
						<Button
							variant="btn-primary-light"
							onClick={() => window.location.reload()}
							className="text-sm px-4 py-1"
							type={'button'}
						>
							Refresh Page
						</Button>
					</div>
				</div>
			)}
			<dialog id="change_password_modal" className="modal">
				<div className=" rounded-lg max-w-2xl md:min-h-[400px] bg-lt-light-popupBackground dark:bg-lt-dark-popupBackground border border-lt-light-popupBorder dark:border-lt-dark-popupBorder">
					<div className="sticky top-0 rounded-lg bg-lt-light-popupBackground dark:bg-lt-dark-popupBackground z-10 p-4">
						<form method="dialog">
							<button
								onClick={(e) => closeChangePasswordModal(e)}
								className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
							>
								✕
							</button>
						</form>
						<h3 className="text-center font-bold text-3xl">Change Password</h3>
					</div>
					<div className="px-20 pb-10 flex items-center min-h-[400px] max-h-[600px]">
						<form className="flex flex-col justify-center items-center gap-4 w-full" onSubmit={handleChangePassword}>
							<Input
								type={isCurrentPasswordVisible ? 'text' : 'password'}
								name="currentPassword"
								value={currentPassword}
								onChange={(e) => setCurrentPassword(e.target.value)}
								placeholder="Current Password"
								required
								icon={
									isCurrentPasswordVisible ? (
										<IconEyeOff onClick={() => setIsCurrentPasswordVisible(false)} className="h-5 w-5 cursor-pointer" />
									) : (
										<IconEye onClick={() => setIsCurrentPasswordVisible(true)} className="h-5 w-5 cursor-pointer" />
									)
								}
							/>
							<Input
								onFocus={handleFocus}
								onBlur={handleBlur}
								ref={newPasswordInputRef}
								type={isPasswordVisible ? 'text' : 'password'}
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
								placeholder="Password"
								name="password"
								required
								icon={
									isPasswordVisible ? (
										<IconEyeOff onClick={() => setIsPasswordVisible(false)} className="h-5 w-5 cursor-pointer" />
									) : (
										<IconEye onClick={() => setIsPasswordVisible(true)} className="h-5 w-5 cursor-pointer" />
									)
								}
							/>
							{isPasswordPopupVisible && (
								<div
									style={{
										position: 'absolute',
										...popupStyle
									}}
								>
									<PasswordPopup password={newPassword} />
								</div>
							)}
							<Button
								disabled={!newPassword || !currentPassword || isChangePasswordLoading}
								variant="btn-primary-light"
								type="submit"
							>
								{isChangePasswordLoading ? (
									<span className="loading loading-spinner loading-md"></span>
								) : (
									'Change Password'
								)}
							</Button>
						</form>
					</div>
				</div>
			</dialog>
			<div className="h-[calc(100dvh-121px)] md:h-[calc(100vh-36px)] overflow-y-auto overflow-x-hidden flex-col items-center w-full max-w-[2000px] pb-5">
				<div
					className={`relative w-full flex justify-center bg-gray-200 dark:bg-gray-700 ${isChrome ? 'mt-[60px] sm:mt-0' : ''}`}
				>
					<div
						className="w-full bg-gray-200 dark:bg-gray-700 aspect-[5/1]"
						style={{
							backgroundImage: myData?.profileBackgroundUrl ? `url("${myData.profileBackgroundUrl}")` : 'none',
							backgroundSize: 'cover',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat'
						}}
					>
						{!myData?.profileBackgroundUrl && (
							<div className="absolute inset-0 flex items-center justify-center">
								<span className="loading loading-spinner loading-xs"></span>
							</div>
						)}
						{myData && (
							<>
								{myData.profileBackgroundUrl ? (
									<div className="relative group w-full h-full">
										<div className="absolute top-0 left-0 w-full h-full cursor-pointer flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity">
											<label className="flex items-center justify-center w-full h-full cursor-pointer">
												<IconPhoto className="text-white" />
												<input type="file" onChange={handleBgFileChange} className="hidden" accept="image/*" />
											</label>
										</div>
										{isBgLoading && (
											<div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
												<span className="loading loading-spinner loading-xs text-white"></span>
											</div>
										)}
									</div>
								) : (
									<div className="relative group w-full h-full bg-[#D0D0D0]">
										<img
											className="object-contain w-full h-full"
											src={noBackgroundPicture}
											alt="no background picture"
										/>
										<div className="absolute top-0 left-0 w-full h-full cursor-pointer flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity">
											<label className="flex items-center justify-center w-full h-full cursor-pointer">
												<IconPhoto className="text-white" />
												<input type="file" onChange={handleBgFileChange} className="hidden" accept="image/*" />
											</label>
										</div>
									</div>
								)}
								<Modal
									isOpen={modalIsOpen}
									onRequestClose={() => setIsOpen(false)}
									className="cropper-modal bg-lt-cream dark:bg-lt-dark-secondary-bg border border-lt-beige dark:border-lt-dark-secondary-border"
								>
									{bgImageUrl && (
										<div className="crop-container w-[360px] h-[120px] md:w-[1000px] md:h-[200px]">
											<Cropper
												image={bgImageUrl}
												crop={crop}
												zoom={zoom}
												aspect={5 / 1} // 5:1 oranı
												onCropChange={setCrop}
												onZoomChange={setZoom}
												onCropComplete={onCropComplete}
												objectFit="horizontal-cover"
											/>
										</div>
									)}
									<Button
										type="button"
										variant="btn-primary-light"
										className="cropper-button"
										onClick={showCroppedImage}
									>
										Crop
									</Button>
								</Modal>
							</>
						)}
						{userData && (
							<>
								{userData.profileBackgroundUrl ? (
									<div className="relative group w-full h-full">
										<img src={userData.profileBackgroundUrl} alt="Background" className="w-full h-full object-cover" />
									</div>
								) : (
									<div className="relative group w-full h-full bg-[#D0D0D0]">
										<img
											className="object-contain w-full h-full"
											src={noBackgroundPicture}
											alt="no background picture"
										/>
									</div>
								)}
							</>
						)}
					</div>

					<div
						className="absolute -bottom-[40%] base:-bottom-[18%] left-[7%] w-[20%] max-w-[200px] rounded-full border-2 border-lt-cream dark:border-black"
						style={{ aspectRatio: '1 / 1' }}
					>
						{myData && (
							<>
								{myData.profileImgUrl ? (
									<>
										<div className="relative group w-full h-full">
											<img
												src={myData.profileImgUrl}
												alt="Profile"
												className="w-full h-full object-cover rounded-full"
											/>
											<div className="absolute top-0 left-0 w-full h-full cursor-pointer flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity rounded-full">
												<label className="flex items-center justify-center w-full h-full cursor-pointer">
													<IconCamera className="text-white" />
													<input type="file" onChange={handleFileChange} className="hidden" accept="image/*" />
												</label>
											</div>
											{isLoading && (
												<div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full">
													<span className="loading loading-spinner loading-xs text-white"></span>
												</div>
											)}
										</div>
										{!myData.profileImgUrl && (
											<div className="absolute inset-0 flex items-center justify-center">
												<span className="loading loading-spinner loading-xs"></span>
											</div>
										)}
									</>
								) : (
									<div className="relative group w-full h-full">
										<img
											src={noProfilePicture}
											alt="no profile picture"
											className="w-full h-full object-cover rounded-full"
										/>
										<div className="absolute top-0 left-0 w-full h-full cursor-pointer flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity rounded-full">
											<label className="flex items-center justify-center w-full h-full cursor-pointer">
												<IconCamera className="text-white" />
												<input type="file" onChange={handleFileChange} className="hidden" accept="image/*" />
											</label>
										</div>
										{isLoading && (
											<div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full">
												<span className="loading loading-spinner loading-xs text-white"></span>
											</div>
										)}
									</div>
								)}
							</>
						)}
						{userData && (
							<>
								{userData.profileImgUrl ? (
									<img src={userData.profileImgUrl} alt="Profile" className="w-full h-full object-cover rounded-full" />
								) : (
									<div className="relative group w-full h-full">
										<img
											src={noProfilePicture}
											className="w-full h-full object-cover rounded-full"
											alt="no profile picture"
										/>
									</div>
								)}
							</>
						)}
					</div>
					{userData && (
						<>
							{!isFollowing ? (
								<div className="absolute bottom-1 right-1 md:bottom-5 md:right-5">
									<Button
										disabled={userData.followRequestSent || sendFollowReqIsLoading || followRequestSendFlag}
										onClick={handleSendRequest}
										variant="btn-secondary-light"
										type="button"
										className="text-sm md:text-base scale-75 sm:scale-100"
									>
										{sendFollowReqIsLoading ? (
											<span className="loading loading-spinner loading-xs"></span>
										) : (
											<>
												<IconUserPlus />
												Send Follow Request
											</>
										)}
									</Button>
								</div>
							) : (
								<div className="absolute bottom-1 right-1 md:bottom-5 md:right-5">
									<Button
										onClick={() => openAYSModule()}
										variant="btn-secondary-light"
										type="button"
										className="text-sm md:text-base scale-75 sm:scale-100"
									>
										<IconUserMinus className="w-4 h-4 md:w-6 md:h-6" />
										Unfollow
									</Button>
								</div>
							)}
						</>
					)}
				</div>

				<div className={`px-8 mt-10 sm:mt-16 md:mt-16 sm:mx-4 ${userId && isFollowing ? '' : 'base:mx-2'}`}>
					{myData && (
						<div className="text-2xl sm:text-5xl base:text-6xl">
							<span className="text-black dark:text-white">{myData.username}</span>
						</div>
					)}
					{userData && (
						<div
							className={`text-2xl sm:text-5xl  base:text-6xl ${userId && !isFollowing && !isMe ? '' : 'base:mx-20'}`}
						>
							<span className="text-black dark:text-white">{userData.username}</span>
						</div>
					)}

					{userProfileIsLoading ? (
						<div className="w-full h-full flex justify-center items-center">
							<span className="loading loading-spinner loading-xs"></span>
						</div>
					) : (
						<>
							{!userId && isMyDataLoading ? (
								<div className="w-full h-full flex justify-center items-center">
									<span className="loading loading-spinner loading-xs"></span>
								</div>
							) : (
								<>
									{myData && (
										<div className="">
											<div className="w-full flex flex-col lg:flex-row mt-2 border-b border-lt-beige dark:border-lt-dark-primary-border pb-4">
												<div className="flex w-full lg:w-1/2 gap-4">
													<Followings
														setFollowRequestSendFlag={setFollowRequestSendFlag}
														setIsFollowing={setIsFollowing}
														followRequestSendFlag={followRequestSendFlag}
													/>
													<Followers
														setFollowRequestSendFlag={setFollowRequestSendFlag}
														followRequestSendFlag={followRequestSendFlag}
													/>
													{isMe && <FollowRequests />}
												</div>
												<div className="w-full lg:w-1/2 flex gap-1 sm:gap-4">
													<Achievements />
													<Stickers />
													<Streaks />
												</div>
											</div>
											<div className="mt-4 w-full">
												<div className="flex flex-col gap-4">
													<div className="relative border border-lt-beige dark:border-lt-dark-primary-border p-4 rounded-lg">
														<h2 className="text-2xl font-semibold mb-2">Daily Rewards</h2>
														<div className=" flex flex-wrap gap-4 justify-center">
															{dailyRewards.map((day) => (
																<div
																	key={day.id}
																	className={`flex flex-col gap-2 items-center border-dashed border-2 ${profile.streakDay.dayCount >= day.id ? 'border-lt-beige dark:border-lt-cream' : 'border-lt-dark-primary-disabled-bg'} rounded-lg py-2 px-0 base:py-5 base:px-4 min-h-[174px] min-w-[130px]`}
																>
																	<span
																		className={`text-lt-dark-primary-hover-bg dark:text-lt-dark-inputPlaceholder text-sm xl:text-base ${profile.streakDay.dayCount >= day.id ? 'text-lt-beige dark:text-lt-cream' : 'text-lt-dark-primary-disabled-bg'}`}
																	>
																		{day.title}
																	</span>
																	<IconCoinFilled
																		className={`${profile.streakDay.dayCount >= day.id ? 'text-[#F5BC00]' : 'text-lt-dark-primary-disabled-bg'} w-8 h-8 xl:w-10 xl:h-10`}
																	/>
																	<span
																		className={`font-semibold ${profile.streakDay.dayCount >= day.id ? 'text-[#F5BC00] dark:text-lt-cream' : 'text-lt-dark-primary-disabled-bg dark:text-lt-dark-primary-disabled-text'}`}
																	>
																		{day.reward}
																	</span>
																	{(profile.streakDay.dayCount === day.id ||
																		(day.id === 7 && profile.streakDay.dayCount > 7)) && (
																		<button
																			disabled={
																				claimStreakRewardLoading ||
																				isSameDay(new Date(profile.streakDay.lastClaimDate), new Date())
																			}
																			onClick={claimStreakReward}
																			className={`px-4 py-3.5 border rounded-lg text-sm ${isSameDay(new Date(profile.streakDay.lastClaimDate), new Date()) ? 'border-none bg-transparent text-lt-dark-inputPlaceholder' : 'bg-lt-beige border-lt-beige  text-white dark:bg-lt-dark-primary-bg dark:border-lt-dark-primary-border dark:hover:bg-lt-dark-primary-hover-bg dark:hover:text-lt-white'} ${claimStreakRewardLoading || isSameDay(new Date(profile.streakDay.lastClaimDate), new Date()) ? 'cursor-not-allowed' : 'cursor-pointer'}`}
																		>
																			{claimStreakRewardLoading ? (
																				<span className="loading loading-spinner loading-xs"></span>
																			) : (
																				<>
																					{isSameDay(new Date(profile.streakDay.lastClaimDate), new Date())
																						? 'Claimed'
																						: 'Claim'}
																				</>
																			)}
																		</button>
																	)}
																</div>
															))}
														</div>

														{profile.subscription.subscriptionType === SubscriptionType.FREE &&
															profile.subscription.isTrial === false && (
																<button
																	onClick={() => {
																		setIsSubscriptionModalOpen(true);
																	}}
																	className="absolute inset-0 bg-gray-700 bg-opacity-75 p-4 flex flex-col rounded-lg justify-center items-center text-white font-bold text-center"
																>
																	<IconLock className="mb-2" />
																	Upgrade to Full Access to Claim Your Rewards
																</button>
															)}
													</div>
													<div className="border border-lt-beige dark:border-lt-dark-primary-border p-4 rounded-lg">
														<h2 className="text-2xl font-semibold w-full md:w-1/4">Personal Info</h2>
														<div className="w-full flex sm:justify-end">
															<div className="w-full sm:w-3/4 flex flex-col gap-4">
																<div className="flex flex-col text-lt-dark-primary-hover-bg dark:text-lt-dark-inputPlaceholder">
																	<label className="text-lg ">Email</label>
																	<input
																		className="w-full sm:w-3/4 lg:w-1/2 rounded-lg border border-lt-lightCream  px-4 py-3"
																		type="text"
																		disabled
																		value={myData.email}
																	/>
																</div>
																<div className="flex flex-col">
																	<label className="text-lg text-lt-dark-primary-hover-bg dark:text-lt-dark-inputPlaceholder">
																		Password
																	</label>
																	<Button
																		onClick={() =>
																			(
																				document.getElementById('change_password_modal') as HTMLDialogElement
																			)?.showModal()
																		}
																		variant="btn-primary-light"
																		type={'button'}
																		className="w-full sm:w-3/4 lg:w-1/2 text-sm"
																	>
																		Change Password
																	</Button>
																</div>
															</div>
														</div>
													</div>
													<div className="border border-lt-beige dark:border-lt-dark-primary-border p-4 rounded-lg">
														<div className="flex flex-col w-full md:w-1/4">
															<h2 className="text-2xl font-semibold">Subscription Details</h2>
															{myData.subscription.subscriptionType !== SubscriptionType.FREE && (
																<span>
																	{timeDiff / (1000 * 60 * 60 * 24) < 5 && (
																		<span className="text-red-500 text-sm">
																			Subscription renewal in {Math.floor(timeDiff / (1000 * 60 * 60 * 24))} days
																		</span>
																	)}
																</span>
															)}
															{myData.subscription.subscriptionType === SubscriptionType.FREE &&
																myData.subscription.isTrial && (
																	<>
																		<span className="text-lt-dark-primary-hover-bg dark:text-lt-dark-inputPlaceholder">
																			Your trial ends in {trialTimeDiff} days
																		</span>
																	</>
																)}
														</div>

														<div className="w-full flex sm:justify-end">
															<div className="w-full sm:w-3/4 flex flex-col gap-4">
																<div className="text-lg text-lt-dark-primary-hover-bg dark:text-lt-dark-inputPlaceholder flex flex-col">
																	Current Subscription
																	<input
																		className="w-full sm:w-3/4 lg:w-1/2 rounded-lg border border-lt-lightCream px-4 py-3"
																		type="text"
																		disabled
																		value={myData.subscription.subscriptionType}
																	/>
																</div>
																{myData.subscription.subscriptionType !== SubscriptionType.FREE && (
																	<div className="text-lg text-lt-dark-primary-hover-bg dark:text-lt-dark-inputPlaceholder flex flex-col gap-4">
																		<div className="flex flex-col">
																			Subscription Start Date{' '}
																			<input
																				className="w-full sm:w-3/4 lg:w-1/2 rounded-lg border border-lt-lightCream px-4 py-3"
																				type="text"
																				disabled
																				value={formattedStartDate}
																			/>
																		</div>
																		<div className="flex flex-col">
																			Subscription End Date
																			<input
																				className="w-full sm:w-3/4 lg:w-1/2 rounded-lg border border-lt-lightCream px-4 py-3"
																				type="text"
																				disabled
																				value={formattedEndDate}
																			/>
																		</div>
																	</div>
																)}

																<div className="text-lg text-lt-dark-primary-hover-bg dark:text-lt-dark-inputPlaceholder flex flex-col">
																	Manage Subscription
																	<div className="flex flex-col gap-4">
																		<div className="w-full sm:w-3/4 lg:w-1/2 p-0.5 sm:p-1.5 bg-white rounded-lg flex justify-between">
																			<input
																				value={promoCode}
																				onChange={(e) => setPromoCode(e.target.value)}
																				type="text"
																				placeholder="Enter Promo Code"
																				className="border-none outline-none ring-0 w-2/3 md:w-full"
																			/>
																			<Button
																				variant="btn-primary-light"
																				type="button"
																				onClick={applyPromoCode}
																				disabled={isApplyPromoCodeLoading}
																				className="text-sm 2xl:text-base"
																			>
																				{isApplyPromoCodeLoading ? (
																					<span className="loading loading-spinner loading-xs"></span>
																				) : (
																					'Apply Code'
																				)}
																			</Button>
																		</div>
																		<Button
																			variant="btn-primary-light"
																			type="button"
																			className="w-full sm:w-3/4 lg:w-1/2 text-sm"
																			onClick={() => {
																				changeSubscription();
																			}}
																		>
																			Change Subscription
																		</Button>
																		{myData.subscription.subscriptionType !== SubscriptionType.FREE &&
																			myData.subscription.status === 'active' && (
																				<button
																					disabled={isCancelSubscriptionLoading}
																					onClick={() => openCancelSubscriptionModal()}
																					className="w-full sm:w-3/4 lg:w-1/2 text-sm bg-red-500 px-4 py-3.5 border border-lt-lightCream rounded-lg text-white"
																				>
																					{isCancelSubscriptionLoading ? (
																						<span className="loading loading-spinner loading-xs"></span>
																					) : (
																						'Cancel Subscription'
																					)}
																				</button>
																			)}
																		{myData.subscription.subscriptionType !== SubscriptionType.FREE &&
																			myData.subscription.status === 'cancelled' && (
																				<button
																					onClick={() => openResumeSubscriptionModal()}
																					disabled={isResumeSubscriptionLoading}
																					className="w-full sm:w-3/4 lg:w-1/2 text-sm bg-green-500 px-4 py-3.5 border border-lt-lightCream rounded-lg text-white"
																				>
																					{isResumeSubscriptionLoading ? (
																						<span className="loading loading-spinner loading-xs"></span>
																					) : (
																						'Resume Subscription'
																					)}
																				</button>
																			)}
																		{myData.subscription.status === 'cancelled' && (
																			<span className="text-red-500 text-sm w-full sm:w-3/4 lg:w-1/2">
																				<strong>Attention!</strong> Your subscription has been canceled. You will lose
																				access to subscription benefits after the end date.
																			</span>
																		)}
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="border border-lt-beige dark:border-lt-dark-primary-border p-4 rounded-lg">
														<label className="text-2xl font-semibold sm:w-1/4">Other</label>
														<div className="w-full flex sm:justify-end">
															<div className="w-full sm:w-3/4 flex flex-col gap-4">
																<div className="text-lg text-lt-dark-primary-hover-bg dark:text-lt-dark-inputPlaceholder flex flex-col">
																	Delete Profile
																	<button
																		onClick={openDeleteModal}
																		className="w-full sm:w-3/4 lg:w-1/2 text-sm bg-red-500 px-4 py-3 border border-lt-lightCream rounded-lg text-white"
																	>
																		Delete Account
																	</button>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									)}
								</>
							)}
							{isUserLoading ? (
								<div className="w-full h-full flex justify-center items-center">
									<span className="loading loading-spinner loading-xs"></span>
								</div>
							) : (
								<>
									{userId && isFollowing && (
										<div className="w-full">
											<div className="w-full flex flex-col lg:flex-row mt-2 border-b border-lt-beige pb-4 base:mx-20">
												<div className="flex w-full base:w-1/2 gap-4">
													<Followings
														setFollowRequestSendFlag={setFollowRequestSendFlag}
														isFollowing={isFollowing}
														setIsFollowing={setIsFollowing}
														followRequestSendFlag={followRequestSendFlag}
													/>
													<Followers
														setFollowRequestSendFlag={setFollowRequestSendFlag}
														followRequestSendFlag={followRequestSendFlag}
														isFollowing={isFollowing}
													/>
													{isMe && <FollowRequests />}
												</div>
												<div className="w-full base:w-1/2 flex gap-1 sm:gap-4">
													<Achievements isFollowing={isFollowing} />
													<Stickers isFollowing={isFollowing} />
													<Streaks isFollowing={isFollowing} />
												</div>
											</div>
											<div className="hidden base:block">
												{showFlipBook ? (
													<FlipBook isFollowing={isFollowing} />
												) : (
													<div className="w-full h-screen flex justify-center items-center">
														<span className="loading loading-spinner loading-xs"></span>
													</div>
												)}
											</div>
										</div>
									)}

									{userId && !isFollowing && (
										<div className="flex flex-col justify-center items-center h-full gap-5 mt-20 text-lt-dark-primary-hover-bg dark:text-lt-dark-inputPlaceholder">
											<IconLock className="w-12 h-12" />
											<h2 className="text-base text-center sm:text-xl font-bold">
												You are not following this user. Follow them to see their profile.
											</h2>
										</div>
									)}
								</>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
}
