import { Link } from 'react-router-dom';
import './index.css';
import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import tableTemplatesWithUnit from '../../../views/Dashboard/MyTables/tableTemplatesWithUnit.json';
import tableTemplatesWithoutUnit from '../../../views/Dashboard/MyTables/tableTemplatesWithoutUnit.json';
import { useTheme } from '../../../context/ThemeContext';

export default function Main() {
	const { theme } = useTheme();
	const [templates, setTemplates] = useState<any[]>([]);

	useEffect(() => {
		const fetchTemplates = async () => {
			const templatesWithUnit = tableTemplatesWithUnit.map((template) => ({
				...template,
				img: `/tableTemplates/${theme}Theme/${template.img}`
			}));
			const templatesWithoutUnit = tableTemplatesWithoutUnit.map((template) => ({
				...template,
				img: `/tableTemplates/${theme}Theme/${template.img}`
			}));
			setTemplates([...templatesWithUnit, ...templatesWithoutUnit]);
		};
		fetchTemplates();
	}, [theme]);

	return (
		<div className="flex flex-col items-center bg-gradient-to-b from-[#c5782b] to-lt-lightCream dark:from-lt-dark-popupBackground w-full box-border px-4 sm:px-5 gap-y-6 pt-20 md:pt-40">
			<div className="flex flex-col items-center md:items-start md:flex-row md:justify-around w-full gap-16">
				<div className="flex flex-col items-center md:items-start gap-y-6">
					<header className="text-[#333333] dark:text-white text-[28px] sm:text-[48px] sm:leading-[52px] font-extrabold text-center md:text-start">
						Routines, Habits, and Life: <br /> Organized and Tracked <br /> in One Place
					</header>
					<h2 className="text-base sm:text-xl font-semibold text-center md:text-start text-[#333333] dark:text-[#BDBDBD]">
						Keep track of your daily life, <br /> decorate your journal with stickers, share with friends!
					</h2>
					<Link
						to="/auth/register"
						className="bg-[#FFD28C] text-[#4F4F4F] dark:bg-lt-dark-primary-bg dark:text-white py-4 2xl:py-5 text-xl px-10 2xl:px-14 rounded-full border-2 border-[#6E6E40] font-bold mt-5"
						type="button"
					>
						Sign Up
					</Link>
				</div>

				<div className="w-[340px]">
					<Swiper
						modules={[Autoplay]}
						spaceBetween={30}
						slidesPerView={1}
						navigation={false}
						pagination={false}
						loop={true}
						speed={800}
						autoplay={{
							delay: 3500,
							disableOnInteraction: false
						}}
						className="mySwiper"
					>
						{templates.map((template, idx) => (
							<SwiperSlide key={idx}>
								<div className="border border-lt-beige bg-lt-light-popupBackground dark:bg-lt-dark-popupBackground dark:border-lt-dark-primary-border rounded-md p-4 opacity-90 shadow-xl">
									<div className="flex justify-start items-center text-base sm:text-xl gap-2 w-full">
										<i className={`${template.icon}`}></i>
										<h6 className="font-bold">{template.title}</h6>
									</div>
									<div className="w-full sm:w-10/12 flex justify-start">
										<img className="w-[300px] h-[450px]" src={template.img} alt="Table Template" />
									</div>
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>

			<div className="w-full h-full flex flex-col items-center justify-center mt-20">
				<span className="text-base sm:text-3xl font-semibold text-center text-[#333333] dark:text-[#BDBDBD]">
					See how simple it is to use Life on Track
				</span>
				<div
					style={{ position: 'relative', paddingBottom: 'calc(54.89417989417989% + 41px)', height: 0, width: '100%' }}
				>
					<iframe
						src="https://demo.arcade.software/6WctO5gNgzElhLTwc8r0?embed&embed_mobile=tab&embed_desktop=inline&show_copy_link=true"
						title="Life on Track - My Tables"
						frameBorder="0"
						loading="lazy"
						allowFullScreen
						allow="clipboard-write"
						className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:w-2/3 sm:h-2/3 w-full h-full rounded-lg"
					/>
				</div>
			</div>
		</div>
	);
}
