import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { persistor, store } from './states/store';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-tooltip/dist/react-tooltip.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import deskImage from '/src/assets/landing-page/desk.webp';
import { useState, useEffect } from 'react';
import LoadingScreen from './components/loadingScreen/LoadingScreen';
import { ThemeProvider } from './context/ThemeContext';
import Modal from 'react-modal';

export const Main = () => {
	document.getElementById('loading-screen')?.classList.add('hidden');
	const [isLoading, setIsLoading] = useState(true);

	Modal.setAppElement('#root');

	useEffect(() => {
		const img = new Image();
		img.src = deskImage;
		img.onload = () => {
			// Load desk image
			setIsLoading(false);
		};
		img.onerror = () => {
			console.error('Image failed to load');
		};
	}, []);

	return (
		<div>
			{isLoading && <LoadingScreen />}
			{!isLoading && (
				<GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
					<Provider store={store}>
						<PersistGate loading={null} persistor={persistor}>
							<ThemeProvider>
								<App />
							</ThemeProvider>
						</PersistGate>
					</Provider>
				</GoogleOAuthProvider>
			)}
		</div>
	);
};

ReactDOM.createRoot(document.getElementById('root')!).render(<Main />);
