import { useState } from 'react';
import './index.css';
import Logo from '/src/assets/logo.svg';
import { Link as ReactLink } from 'react-scroll';

export default function Navbar() {
	const [isOpen, setIsOpen] = useState(false);

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	return (
		<div className="sticky top-0 z-50 w-full h-[70px] px-4 md:px-12 flex items-center justify-between border-b border-[#c5782b]  dark:border-lt-dark-secondary-border bg-[#c5782b] dark:bg-lt-dark-secondary-bg">
			<div className="flex items-center">
				<img src={Logo} alt="logo" className="w-8 h-8 md:w-10 md:h-10" />
				<a href="" className="font-bold text-base sm:text-xl text-white dark:text-white">
					Life on Track
				</a>
			</div>
			<nav className="hidden sm:block w-6/12 md:w-1/4 ">
				<ul className="flex text-xs sm:text-base font-semibold justify-between text-[#F8D7A3] dark:text-[#BDBDBD]">
					<li>
						<ReactLink
							className="cursor-pointer"
							to="features"
							smooth={true}
							duration={500}
							role="button"
							aria-label="Scroll to features"
							containerId="home-container"
						>
							Features
						</ReactLink>
					</li>
					<li>
						<ReactLink
							className="cursor-pointer"
							to="faq"
							smooth={true}
							duration={500}
							role="button"
							aria-label="Scroll to FAQ"
							containerId="home-container"
						>
							FAQ
						</ReactLink>
					</li>
					<li>
						<ReactLink
							className="cursor-pointer"
							to="pricing"
							smooth={true}
							duration={500}
							role="button"
							aria-label="Scroll to pricing"
							containerId="home-container"
						>
							Pricing
						</ReactLink>
					</li>
				</ul>
			</nav>
			<div className="hidden sm:block">
				<a
					className="bg-[#FFD28C] text-[#4F4F4F] py-2 px-4 sm:px-8 rounded-full border-2 border-[#6E6E40] dark:bg-lt-dark-primary-bg dark:text-white font-bold"
					type="button"
					href="/auth/login"
				>
					Sign In
				</a>
			</div>
			<nav className="sm:hidden flex items-center justify-end p-4 z-50 relative">
				<button onClick={toggleMenu} className={`hamburger ${isOpen ? 'open' : ''}`}>
					<span className="line top"></span>
					<span className="line middle"></span>
					<span className="line bottom"></span>
				</button>
			</nav>
			{isOpen && (
				<div className="sm:hidden fixed inset-0 top-[70px] bg-black bg-opacity-80 flex flex-col items-start justify-start z-40 p-5">
					<ul className="flex flex-col text-3xl font-semibold justify-between text-[#F8D7A3] dark:text-white gap-10">
						<li>
							<ReactLink
								onClick={toggleMenu}
								className="cursor-pointer"
								to="features"
								smooth={true}
								duration={500}
								role="button"
								aria-label="Scroll to features"
								containerId="home-container"
							>
								Features
							</ReactLink>
						</li>
						<li>
							<ReactLink
								onClick={toggleMenu}
								className="cursor-pointer"
								to="faq"
								smooth={true}
								duration={500}
								role="button"
								aria-label="Scroll to FAQ"
								containerId="home-container"
							>
								FAQ
							</ReactLink>
						</li>
						<li>
							<ReactLink
								onClick={toggleMenu}
								className="cursor-pointer"
								to="pricing"
								smooth={true}
								duration={500}
								role="button"
								aria-label="Scroll to pricing"
								containerId="home-container"
							>
								Pricing
							</ReactLink>
						</li>
						<li>
							<a onClick={toggleMenu} type="button" href="/auth/login">
								Sign In
							</a>
						</li>
					</ul>
				</div>
			)}
		</div>
	);
}
